import CourseHeader from "./CourseHeader";
import CourseImage from "./CourseImage";
import CourseMetrics from "./CourseMetrics";
import CoursePricing from "./CoursePricing";

export default function CourseThumb({
  course,
  hideHeader,
  hideMetrics,
  className,
}) {
  // console.log(course);
  return (
    <div
      className={`group relative w-[307px] cursor-pointer rounded-lg overflow-hidden shadow-lg duration-500 ease-in-out hover:scale-105 border-2 border-blue-500/50 bg-blue-700/20 dark:bg-transparent dark:border-blue-400/50 ${className || ''}`}
    >
      {hideHeader ? null : <CourseHeader course={course} />}
      <CourseImage course={course} />
      {hideMetrics ? null : <CourseMetrics course={course} />}
      <CoursePricing course={course} />
    </div>
  );
}
