import Link from "next/link";
import dayjs from "dayjs";
import priceHelper from "../../../helpers/priceHelper";
import extractFilename from "../../../helpers/extractFilename";
const relativeTime = require("dayjs/plugin/relativeTime");
dayjs.extend(relativeTime);

export default function CourseImage({ course }) {
	const cleanUrl = course.cleanUrl.replace(/\//g, "");
	const priceData = priceHelper(course);
	const image = extractFilename(course.image);
	return (
        <Link
			href={`/udemy/${cleanUrl.replace(/\//g, "")}`}
			as={`/udemy/${cleanUrl.replace(/\//g, "")}`}
			passHref
			prefetch={false}
		>
            <div className="relative">
                <picture className="">
                    <source
                        srcSet={`https://thumbs.comidoc.net/480/webp/${image?.imageFilenameWithoutExtension}.webp`}
                        type="image/webp"
                    />
                    <source
                        srcSet={`https://thumbs.comidoc.net/480/${image?.imageFilename}`}
                        type="image/jpeg"
                    />
                    <img
                        src={`https://thumbs.comidoc.net/480/${image?.imageFilename}`}
                        alt={course.title}
                        height={200}
                        width={310}
                        itemProp="thumbnail"
                        className=""
                        loading="lazy"
                    />
                </picture>

                <div className="flex items-center justify-center h-24 p-4 font-bold text-center text-white border-t bg-blue-600/20 dark:bg-transparent dark:border-blue-400/30">
                    <h2>{course.title}</h2>
                </div>
            </div>
        </Link>
    );
}
