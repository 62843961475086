import kFormater from "../../../helpers/kFormater";
import formatUpdatedAt from "../../../helpers/formatedUpdatedAt";

export default function CourseMetrics({ course }) {
  const cleanUrl = course.cleanUrl.replace(/\//g, "");
  return (
    <div className="border-t border-b cursor-default bg-blue-600/20 dark:bg-transparent border-blue-400/30 dark:border-blue-400/30">
      <div className="flex justify-between text-white/80">
        <div className="flex flex-col w-full py-4 text-center">
          <div className="text-sm font-bold text-white">
            {kFormater(course.subscribers)}
          </div>
          <div className="text-xs font-normal">students</div>
        </div>
        <div className="flex flex-col w-full py-4 text-center">
          <div className="text-sm font-bold text-white">
            {course.length.replace(/total/g, "")}
          </div>
          <div className="text-xs font-normal">content</div>
        </div>
        <div className="flex flex-col w-full py-4 text-center">
          <div className="text-sm font-bold text-white">
            {formatUpdatedAt(course.updated)}
          </div>
          <div className="text-xs font-normal">updated</div>
        </div>
      </div>
    </div>
  );
}
