import { Badge } from "@/components/ui/badge";
import { motion, useMotionValue, animate } from "framer-motion";
import { useEffect, useState } from "react";
import { lightTheme } from "@/styles/theme-variables";

export function CountBadge({ count }) {
  const [formattedCount, setFormattedCount] = useState("");
  const countValue = useMotionValue(0);
  
  // Animation d'entrée du badge
  const badgeVariants = {
    initial: { 
      opacity: 0,
      scale: 0.6,
    },
    animate: { 
      opacity: 1,
      scale: 1,
      transition: { 
        duration: 0.6,
        ease: [0.23, 1, 0.32, 1]
      }
    }
  };
  
  // Animation du compteur
  useEffect(() => {
    const controls = animate(countValue, count, {
      duration: 1.5,
      ease: "easeOut"
    });
    
    return controls.stop;
  }, [count, countValue]);
  
  // Formater le nombre à chaque changement de la valeur animée
  useEffect(() => {
    const unsubscribe = countValue.onChange(value => {
      setFormattedCount(new Intl.NumberFormat('fr-FR').format(Math.floor(value)));
    });
    
    return unsubscribe;
  }, [countValue]);
  
  return (
    <motion.div
      initial="initial"
      animate="animate"
      variants={badgeVariants}
      whileHover={{ 
        scale: 1.08,
        transition: { duration: 0.3 }
      }}
    >
      <Badge 
        className="ml-3 h-8 rounded-full bg-blue-500/30 px-3 py-2 text-center text-sm font-bold text-white border-none shadow-none sm:h-10 sm:px-4 sm:text-base relative overflow-hidden flex items-center justify-center"
        style={{ 
          backgroundColor: 'rgba(59, 130, 246, 0.3)',
          boxShadow: 'none',
          width: '100px', // Largeur fixe suffisante pour 6 chiffres
          minWidth: '100px',
          maxWidth: '100px'
        }}
      >
        <div className="w-full text-center">
          <motion.span className="relative z-10 inline-block">
            {formattedCount}
          </motion.span>
        </div>
        <motion.div 
          className="absolute inset-0 bg-blue-500/10"
          animate={{
            x: ["0%", "100%"],
          }}
          transition={{
            duration: 2,
            ease: "easeInOut",
            repeat: 0,
            repeatType: "reverse"
          }}
        />
      </Badge>
    </motion.div>
  );
} 