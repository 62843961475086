import Link from "next/link";
import priceHelper from "../../../helpers/priceHelper";

export default function CoursePricing({ course }) {
  const priceData = priceHelper(course);
  const cleanUrl = course.cleanUrl.replace(/\//g, "");

  return (
    <Link
      href={`/udemy/${cleanUrl.replace(/\//g, "")}`}
      as={`/udemy/${cleanUrl.replace(/\//g, "")}`}
      passHref
      prefetch={false}
    >
      <div
        className={`flex h-[55px] flex-col justify-center tracking-widest text-${priceData.colorTailwind} text-center font-bold dark:bg-transparent`}
      >
        {priceData.text}
      </div>
    </Link>
  );
}