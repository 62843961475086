function cleanInstructorUrl(url) {
  let cleanUrl = url;
  if (url?.slice(0, 6) === "/user/") {
    cleanUrl = cleanUrl.substring(6);
    // Remove trailing slash if present
    if (cleanUrl.endsWith('/')) {
      cleanUrl = cleanUrl.slice(0, -1);
    }
  }
  return cleanUrl;
}

module.exports = cleanInstructorUrl;
