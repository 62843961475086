/**
 * Définition des variables de thème centralisées
 * Ce fichier contient toutes les couleurs et variables de thème utilisées dans l'application
 */

// Thème principal (light)
export const lightTheme = {
  // Couleurs de fond
  background: {
    primary: 'bg-gradient-to-r from-blue-600 to-blue-700',
    secondary: 'bg-blue-700',
    accent: 'bg-blue-700/20',
    card: 'bg-blue-700/20',
    hover: 'hover:bg-blue-700/50',
  },
  
  // Bordures
  border: {
    primary: 'border-blue-500/50',
    secondary: 'border-blue-500/30',
    accent: 'border-blue-500/20',
  },
  
  // Texte
  text: {
    primary: 'text-white',
    secondary: 'text-white/80',
    muted: 'text-white/60',
  },
  
  // Ombres
  shadow: {
    sm: 'shadow-sm',
    md: 'shadow-md',
    lg: 'shadow-lg',
    xl: 'shadow-xl',
  },
  
  // Effets hover
  hover: {
    scale: 'hover:scale-105',
    shadow: 'hover:shadow-xl',
    transition: 'transition-all duration-300 ease-in-out',
  },
  
  // Boutons
  button: {
    primary: 'bg-blue-700/30 hover:bg-blue-700/50 text-white border-blue-500/50',
    secondary: 'bg-white/10 hover:bg-white/20 text-white',
  },
  
  // Icônes
  icon: {
    container: 'h-8 w-8 flex items-center justify-center rounded-full bg-white/10 text-white hover:bg-white/20 transition-colors',
    size: 'h-5 w-5',
  },
  
  // Composants spécifiques
  tabs: {
    list: 'bg-blue-700/30 p-1 rounded-xl shadow-inner border border-blue-500/30',
    trigger: {
      inactive: 'text-white/80 border border-transparent',
      active: 'text-white border-blue-500/30 bg-blue-600/40 shadow-md scale-[1.03]',
      hover: 'hover:bg-blue-600/30 hover:border-blue-500/20 hover:text-white hover:scale-[1.02] hover:shadow-sm',
    },
  },
  
  // Cartes de cours
  courseCard: {
    container: 'rounded-lg overflow-hidden border-2 border-blue-500/50 bg-blue-700/20 shadow-lg hover:scale-105 hover:shadow-xl transition-all duration-300 ease-in-out',
  },
  
  // Couleurs spécifiques pour les prix
  priceColors: {
    free: {
      colorTailwind: 'green',
      colorClass: 'text-green-400',
    },
    discount: {
      colorTailwind: 'red',
      colorClass: 'text-red-500',
    },
    expired: {
      colorTailwind: 'white',
      colorClass: 'text-white',
    },
    coupon: {
      colorTailwind: 'yellow',
      colorClass: 'text-yellow-500',
    },
  },
  
  // Drawer menu
  drawer: {
    background: 'bg-gradient-to-r from-blue-600 to-blue-700',
    itemActive: 'bg-blue-700/60 !text-white',
    itemInactive: 'text-white/70 hover:bg-blue-700/50 hover:text-white',
    headerText: '!text-white',
  },
  
  // Header
  header: {
    background: 'bg-gradient-to-r from-blue-600 to-blue-700',
    border: 'border-blue-500/50',
    navActive: 'bg-blue-700/60 text-white',
    navInactive: 'text-white/90 hover:bg-blue-700/50 hover:text-white',
    button: 'bg-white/10 border-white/20 hover:bg-white/20 text-white',
  },
};

// Thème sombre
export const darkTheme = {
  // Couleurs de fond
  background: {
    primary: 'bg-gray-900',
    secondary: 'bg-gray-800',
    accent: 'bg-gray-800/20',
    card: 'bg-gray-800/20',
    hover: 'hover:bg-gray-700/50',
  },
  
  // Bordures
  border: {
    primary: 'border-gray-700/50',
    secondary: 'border-gray-700/30',
    accent: 'border-gray-700/20',
  },
  
  // Texte
  text: {
    primary: 'text-white',
    secondary: 'text-gray-300',
    muted: 'text-gray-400',
  },
  
  // Ombres
  shadow: {
    sm: 'shadow-sm',
    md: 'shadow-md',
    lg: 'shadow-lg',
    xl: 'shadow-xl',
  },
  
  // Effets hover
  hover: {
    scale: 'hover:scale-105',
    shadow: 'hover:shadow-xl',
    transition: 'transition-all duration-300 ease-in-out',
  },
  
  // Boutons
  button: {
    primary: 'bg-gray-800/30 hover:bg-gray-700/50 text-white border-gray-700/50',
    secondary: 'bg-white/10 hover:bg-white/20 text-white',
  },
  
  // Icônes
  icon: {
    container: 'h-8 w-8 flex items-center justify-center rounded-full bg-white/10 text-white hover:bg-white/20 transition-colors',
    size: 'h-5 w-5',
  },
  
  // Composants spécifiques
  tabs: {
    list: 'bg-gray-800/30 p-1 rounded-xl shadow-inner border border-gray-700/30',
    trigger: {
      inactive: 'text-gray-300 border border-transparent',
      active: 'text-white border-gray-700/30 bg-gray-700/40 shadow-md scale-[1.03]',
      hover: 'hover:bg-gray-700/30 hover:border-gray-700/20 hover:text-white hover:scale-[1.02] hover:shadow-sm',
    },
  },
  
  // Cartes de cours
  courseCard: {
    container: 'rounded-lg overflow-hidden border-2 border-gray-700/50 bg-gray-800/20 shadow-lg hover:scale-105 hover:shadow-xl transition-all duration-300 ease-in-out',
  },
  
  // Couleurs spécifiques pour les prix
  priceColors: {
    free: {
      colorTailwind: 'green',
      colorClass: 'text-green-400',
    },
    discount: {
      colorTailwind: 'red',
      colorClass: 'text-red-500',
    },
    expired: {
      colorTailwind: 'white',
      colorClass: 'text-white',
    },
    coupon: {
      colorTailwind: 'yellow',
      colorClass: 'text-yellow-500',
    },
  },
  
  // Drawer menu
  drawer: {
    background: 'bg-gradient-to-r from-blue-600 to-blue-700',
    itemActive: 'bg-blue-700/60 !text-white',
    itemInactive: 'text-white/70 hover:bg-blue-700/50 hover:text-white',
    headerText: '!text-white',
  },
  
  // Header
  header: {
    background: 'bg-gradient-to-r from-blue-600 to-blue-700',
    border: 'border-blue-500/50',
    navActive: 'bg-blue-700/60 text-white',
    navInactive: 'text-white/90 hover:bg-blue-700/50 hover:text-white',
    button: 'bg-white/10 border-white/20 hover:bg-white/20 text-white',
  },
};

// Exportation du thème par défaut
export const defaultTheme = lightTheme; 